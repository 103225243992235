.root {
  color: black;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 48px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.embedContainer {
  display: block;
  position: relative;
  overflow: hidden;

  .box {
    position: absolute;
    display: block;
    left: -50%;
    top: -50%;
    width: 100%;
    height: 100%;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 2s;

    &.slide {
      animation-name: slide;
    }
  }
}

@keyframes slide {
  from {
    left: -50%;
    top: -50%;
  }
  to {
    left: 100%;
    top: 100%;
  }
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: blue;
  font-size: 1.2em;
  margin-bottom: 0.7em;
}

.email {
  margin-top: 1em;
  font-size: 0.4em;

  a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: blue;
    display: block;
    &:hover {
      color: lighten(blue, 10%);
    }

    &:active {
      color: darken(blue, 10%);
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 480px) {
  .root {
    font-size: 24px;
  }

  .email {
    font-size: 12px;
  }
}
